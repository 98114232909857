import React, {useState} from 'react';
import styles from './Scroll07.module.scss';
import {AWARDS_AND_PRESS} from './navigationNames';

const TEXT = {
    title: {
        thin: 'Our',
        bold: 'Solution'
    },
    leftTitle: 'Live QR/NFC/Web Help Points',
    rightTitle: 'Fixed Touch Tablet Screen Help Points',
    left: 'Teleportivity’s patented ‘print-and-place’ solution allows you to create and deploy thousands of unique QR/' +
        'NFC/Web Links that can be placed in any space or on any object. When scanned through a mobile device ' +
        '(with no app needed), each code directs customers to its own highly bespoke website, customized to ' +
        'reflect any branding, and made just for the situation. Place one in a boardroom, and your customers can ' +
        'get customized session materials and specific tech support. Place one on a portable screen, and your ' +
        'technician can access installation information customized to that single geographic site. Place one on a ' +
        'product in a retail environment, and customers can access the latest information, and talk to your sales ' +
        'team! We’re making every space, place and object a portal to a world of help. From live remote help ' +
        'sessions to surveys to tutorial videos, we’ve got every base covered.',
    right: 'Teleportivity’s cloud based video help-desk system for fixed touch screen kiosks allow you to also offer a ' +
        'whole new, entirely controllable dimension to consistently high-traffic customer areas. Student orientation ' +
        'day? Our fixed digital touch screens can help support and assist students in an orderly fashion. High ' +
        'footfall in one particular location? Simply redeploy your workforce where they’re needed most. ' +
        'Teleportivity lets you scale, push and pull your very best staff where they’re required; control high traffic ' +
        'locations; and provide a constant, seamless customer experience, perfectly complementing our mobile ' +
        'platform to create a holistic, unparalleled customer experience.',
    hidden: 'Teleportivity’s patented ‘print-and-place’ solution allows you to create and deploy thousands of unique QR/ ' +
        'NFC/Web Links that can be placed in any space or on any object. When scanned through a mobile device ' +
        '(with no app needed), each code directs customers to its own highly bespoke website, customized to ' +
        'reflect any branding, and made just for the situation. Place one in a boardroom, and your customers can ' +
        'get customized session materials and specific tech support. Place one on a portable screen, and your ' +
        'technician can access installation information ...'
};

function Scroll07() {
    const [showAll, setShowAll] = useState(false);
    return (
        <div className={styles.container} id={AWARDS_AND_PRESS}>
            <div className={styles.title}>
                <h1>{TEXT.title.thin}</h1>
                <h1> {TEXT.title.bold}</h1>
            </div>
            <div className={styles.mobileText}>
                {showAll
                    ? <div>
                        <h3>{TEXT.leftTitle}</h3>
                        {TEXT.left}
                        <h3>{TEXT.rightTitle}</h3>
                        {TEXT.right}
                    </div>
                    : <div>
                        <h3>{TEXT.leftTitle}</h3>
                        {TEXT.hidden}
                    </div>
                }
                <div className={styles.grey} onClick={setShowAll.bind(null, true)}>{!showAll && 'View all'}</div>
            </div>
            <div className={styles.textContent}>
                <div className={styles.left}><h2>{TEXT.leftTitle}</h2>{TEXT.left}</div>
                <div className={styles.right}><h2>{TEXT.rightTitle}</h2>{TEXT.right}</div>
            </div>
            <div className={styles.logo1}>
                {[1, 2, 3, 4, 5, 6].map(i => <div key={`logo_${i}`} className={styles[`logo_${i}`]}/>)}
            </div>
            <div className={styles.logo2}>
                {[7, 8, 9, 10, 11, 12, 13, 14].map(i => <div key={`logo_${i}`} className={styles[`logo_${i}`]}/>)}
            </div>
        </div>
    );
}

export default Scroll07;
