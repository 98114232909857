import React from 'react';
import PropTypes from 'prop-types';

Icon.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    activeColor: PropTypes.string,
    active: PropTypes.bool
};

Icon.defaultProps = {
    className: '',
    color: 'var(--darkblue)',
    activeColor: 'var(--white)',
    active: false
};

export default function Icon({color = 'var(--darkblue)', activeColor = 'var(--white)', className = '', active = false}) {
    const actualColor = active ? activeColor : color;
    return (
        <svg className={className} width="34" height="34" viewBox="0 0 34 34" fill="none">
            <path
                d="M6.5 34v-8.5c-3.5-3.5-5-7-5-11.5 0-7.5 6-13 12.5-13 7.5 0 12.5 3 14.5 10.5l3.337 5.561a2 2 0 01-.903 2.857L28.5 21v4c0 3.5-2 5-7 4.5V34"
                stroke={actualColor}
                strokeWidth="2"/>
            <path
                d="M20 15a6 6 0 11-12 0 6 6 0 0112 0zm-6-6V2m4.5 17.5l3 2.5"
                stroke={actualColor}
                strokeWidth="2"/>
            <circle cx="23" cy="23" r="2" stroke={actualColor} strokeWidth="2"/>
            <circle cx="14" cy="15" r="2" stroke={actualColor} strokeWidth="2"/>
        </svg>
    );
}
