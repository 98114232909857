import React from 'react';
import styles from './Scroll05.module.scss';
import CircleCheckMark from '../../components/Icons/CircleCheckMark';

const TEXT = {
    index: 1,
    thin: 'Your instantly scalable',
    bold: 'video helpdesk',
    subContent: [
        {text: 'Scale, push and pull your very best staff where they’re required'},
        {text: 'Control high traffic locations'},
        {text: 'Provide a constant, seamless customer experience'}
    ]
};

function Scroll05() {
    return <div className={styles.container}>
        <div className={styles.circledBackground}>
            <div className={`${styles.circle0} ${styles.circle}`}>
                <div className={styles.image}/>
            </div>
            <div className={`${styles.circle1} ${styles.circle}`}>
                <div className={styles.image}/>
            </div>
            <div className={`${styles.circle2} ${styles.circle}`}>
                <div className={styles.image}/>
            </div>
            <div className={`${styles.circle3} ${styles.circle}`}>
                <div className={styles.image}/>
            </div>
            <div className={`${styles.circle4} ${styles.circle}`}>
                <div className={styles.image}/>
            </div>
        </div>
        <div className={styles.textContent}>
            <h1>{TEXT.thin || ''}</h1>
            <h2>{TEXT.bold}</h2>
            {TEXT.subContent.map((el, i) => {
                return (
                    <div key={i} className={styles.listItem}>
                        <CircleCheckMark/>
                        {el.text}
                    </div>
                );
            })}

        </div>

    </div>;
}

export default Scroll05;
