import React, {useContext, useEffect, useState} from 'react';
import classnames from 'classnames';
import styles from './Menu.module.scss';
import {Letter} from '../Icons';
import {ContentContext} from '../../context/ContentContext';
import {NavigationContext} from '../../context/NavigationContextContainer';
import {AWARDS_AND_PRESS, CONTACT_US, HOME} from '../../containers/ScrollPages/navigationNames';

const LINKS = [
    {label: 'Home', to: '/', section: 0, mobileTo: HOME},
    {label: 'Awards & Press', to: '/', section: 6, mobileTo: AWARDS_AND_PRESS},
    {label: 'Media', to: '/', section: 6, mobileTo: AWARDS_AND_PRESS},
    {label: 'Blog', to: '/blog', isExternal: true, url: 'https://teleportivity.blogspot.com/'},
    {label: 'Contact us', to: '/', section: 7, mobileTo: CONTACT_US}
];
const BOTTOM_LINKS = [
    {label: 'Terms and Conditions', to: '/terms-and-conditions'},
    {label: 'Privacy Policy', to: '/policy'}
];

const EMAIL = 'contactus@teleportivity.com';

function Menu() {
    const {showMenu: active, setShowMenu, scrollerRef, setHeaderType, isMobileLayout} = useContext(ContentContext);
    const {goTo: navigateTo, goToHref} = useContext(NavigationContext);
    const goTo = (address, section, mobileTo) => {
        setShowMenu(false);
        navigateTo(address);
        setTimeout(() => {
            if (section > -1) {
                if (isMobileLayout) {
                    setHeaderType('reduceOnScroll');
                    goToHref(mobileTo);
                } else {
                    scrollerRef.current.goToPage(section);
                }
            }
        }, 100);
    };

    return (
        <div className={classnames(styles.container, active && styles.active)}>
            <div className={styles.right}>
                <div className={styles.linkBlock}>
                    {LINKS.map((link, index) => <div key={index} className={styles.link}>
                        {link.isExternal
                            ? <a className={styles.text} target="_blank" rel='noopener' href={link.url}>{link.label}</a>
                            : <div onClick={goTo.bind(null, link.to, link.section, link.mobileTo)}
                                   className={styles.text}>
                                {link.label}
                            </div>}
                        <div className={styles.line}/>
                    </div>)}
                </div>
                <div className={styles.contacts}>
                    <div className={styles.email}>
                        <Letter color='white'/>
                        <a href={`mailto:${EMAIL}`} target="_blank" rel='noopener'>{EMAIL}</a>
                    </div>
                    <div className={styles.terms}>
                        {BOTTOM_LINKS.map((elem, index) => <div key={index} onClick={goTo.bind(null, elem.to)}>{elem.label}</div>)}
                    </div>
                    <div className={styles.copyright}>
                        <p>© 2020, Teleportivity</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Menu;
