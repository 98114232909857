import image0 from "./scroll3_0.png";
import image1 from "./scroll3_1.png";
import image2 from "./scroll3_2.png";
import image3 from "./scroll3_3.png";
import image4 from "./scroll3_4.png";
import image5 from "./scroll3_5.png";
import image5_0 from "./scroll3_5_0.png";
import image5_1 from "./scroll3_5_1.png";
import image5_2 from "./scroll3_5_2.png";
import image5_3 from "./scroll3_5_3.png";

import image0_05x from "./05x/scroll3_0.png";
import image1_05x from "./05x/scroll3_1.png";
import image2_05x from "./05x/scroll3_2.png";
import image3_05x from "./05x/scroll3_3.png";
import image4_05x from "./05x/scroll3_4.png";
import image5_05x from "./05x/scroll3_5.png";
import image5_0_05x from "./05x/scroll3_5_0.png";
import image5_1_05x from "./05x/scroll3_5_1.png";
import image5_2_05x from "./05x/scroll3_5_2.png";
import image5_3_05x from "./05x/scroll3_5_3.png";
import {constraint05x, constraint1x} from '../../helpers/constants';

const SCROLL3_IMAGES = [
    {scr: image0, srcSet: `${image0_05x} ${constraint05x}, ${image0} ${constraint1x}`},
    {scr: image1, srcSet: `${image1_05x} ${constraint05x}, ${image1} ${constraint1x}`},
    {scr: image2, srcSet: `${image2_05x} ${constraint05x}, ${image2} ${constraint1x}`},
    {scr: image3, srcSet: `${image3_05x} ${constraint05x}, ${image3} ${constraint1x}`},
    {scr: image4, srcSet: `${image4_05x} ${constraint05x}, ${image4} ${constraint1x}`},
    {scr: image5, srcSet: `${image5_05x} ${constraint05x}, ${image5} ${constraint1x}`},
    {scr: image5_0, srcSet: `${image5_0_05x} ${constraint05x}, ${image5_0} ${constraint1x}`},
    {scr: image5_1, srcSet: `${image5_1_05x} ${constraint05x}, ${image5_1} ${constraint1x}`},
    {scr: image5_2, srcSet: `${image5_2_05x} ${constraint05x}, ${image5_2} ${constraint1x}`},
    {scr: image5_3, srcSet: `${image5_3_05x} ${constraint05x}, ${image5_3} ${constraint1x}`}];

export default SCROLL3_IMAGES;
