import React, {useContext, useEffect} from 'react';
import classnames from 'classnames';
import styles from './PrivacyPolicyPage.module.scss';
import {ContentContext} from '../context/ContentContext';
import {TEXT} from './PrivacyPolicyText';
import {THEMES} from '../helpers/constants';

const ADDRES = 'Our mailing address is:\n' +
    '\tTeleportivity\n' +
    '\tPO Box 444\n' +
    '\tSydney, NSW 2029\n' +
    '\tAustralia ';

function PrivacyPolicyPage() {
    const {setHeaderColor, setCurrentPageIndex, setHeaderType} = useContext(ContentContext);
    useEffect(() => {
        setCurrentPageIndex(0);
        setHeaderColor(THEMES.DARK);
        setHeaderType('reduceOnScroll');
        return () => {
            setHeaderType('');
        };
    }, []);

    return (
        <div>
            <div className={styles.container}>
                <h1 className={styles.title}>{TEXT.title[0]}<b>{TEXT.title[1]}</b></h1>
                <div className={styles.introduce}>{TEXT.introduce}</div>
                {
                    TEXT.blocks.map(block =>
                        <div className={classnames(styles.block, !block.title && styles.emptyTitle)}>
                            {block.title &&
                            <h3 className={classnames(styles.left, styles.subtitle, styles.wide)}>{block.title}</h3>}
                            {block.body.length === 1
                                ? <SingleBlock block={block}/>
                                : <DoubleBlock block={block}/>}
                        </div>
                    )
                }
                <div className={classnames(styles.block)}>
                    <h3 className={classnames(styles.left, styles.subtitle, styles.wide)}>Contact Details </h3>
                    <div className={styles.wide}>
                        <p>If you have any questions about the data we control or process, our contact details are as
                            follows:<br/>
                            Email: <a href={'mailto:contactus@teleportivity.com'} target="_blank"  rel='noopener'>contactus@teleportivity.com</a><br/>
                            {ADDRES}
                        </p>
                    </div>

                </div>
            </div>
        </div>
    );
}


function SingleBlock({block}) {
    if (block.withLinks) return <BlockWithLinks block={block}/>;
    return (
        <div className={styles.wide}>
            <p>{block.body[0].text}</p>
        </div>
    );
}

function DoubleBlock({block}) {
    const subtitleStyle = block.grey && styles.grey;
    return <>
        <div className={styles.doubleBlock}>
            {block.body[0].subtitle &&
            <h3 className={classnames(styles.first, styles.subtitle, subtitleStyle)}>{block.body[0].subtitle}</h3>}
            {block.body[1].subtitle &&
            <h3 className={classnames(styles.second, styles.subtitle, subtitleStyle)}>{block.body[1].subtitle}</h3>}
            <p className={styles.first}>{block.body[0].text}</p>
            <p className={styles.second}>{block.body[1].text}</p>
        </div>
    </>;
}

function BlockWithLinks({block}) {
    return (
        <div className={styles.left}>
            <h3 className={classnames(styles.subtitle, styles.grey)}>{block.body[0].subtitle}</h3>
            <div>
                {block.body[0].text.map(elem => <p>{elem.text} <a target='_blank' rel='noopener' href={elem.link}>{elem.link}</a></p>)}
            </div>
        </div>
    );
}

export default PrivacyPolicyPage;
