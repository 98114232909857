import React, {useContext, useEffect, useState} from 'react';
import classnames from 'classnames';
import {MouseSmall, Play} from '../../components/Icons';
import styles from './Scroll01.module.scss';
import {ContentContext} from '../../context/ContentContext';
import {HOME} from './navigationNames';

const PAGE_DISPLAY_DURATION = 5000;
const TEXTS = [
    null,
    {
        normal: [`Unlocking Next Level`, `Customer Experience?`],
        bold: `We've got the code`
    },
    {
        normal: [`On-site support`],
        bold: `Just got scalable`
    },
    {
        normal: [`Help is now,`, `quite literally,`],
        bold: `at hand.`
    },
    {
        normal: [`This is customer support`],
        bold: `as you've never seen it!`
    },
    {
        normal: [`On-site support`],
        bold: `Just got scalable`
    },
    {
        reverse: true,
        normal: [`at the perfect place`, 'at the perfect time!'],
        bold: `The perfect help`
    }
];

function Scroll01() {
    const [activeDiv, setActiveDiv] = useState(-1);
    useEffect(() => {
        setTimeout(()=>setActiveDiv(x => x + 1), 100);
    }, []);
    useEffect(() => {
        const timer = setInterval(() => {
            setActiveDiv(n => n + 1);
        }, PAGE_DISPLAY_DURATION);
        return () => clearInterval(timer);
    }, []);

    const activeIndex = ((activeDiv || 1) - 1) % 6 + 1;
    return (
        <div className={styles.container} id={HOME}>
            {[1, 2, 3, 4, 5, 6].map(index =>
                <ScrollScreen
                    index={index}
                    key={`back_${index}`}
                    active={activeIndex === index}
                    className={styles[`back_${index}`]}
                />)
            }
        </div>
    );
}

function ScrollScreen({index, className, active}) {

    const {setVideoPlaying, imageFormat} = useContext(ContentContext);
    const key = `scroll01_block_0${index}`;
    const innerImageStyle = classnames(styles[imageFormat],
        index % 2 === 1 && styles[`centralHand_${index}`],
        index % 2 === 0 && styles[`centralTable_${index}`],
        active && styles.visible);
    const boldTextElement = <h2 className={styles.bold}>{TEXTS[index].bold}</h2>;
    const boldTextWithPosition = TEXTS[index].reverse ? [boldTextElement, null] : [null, boldTextElement];
    return (
        <div className={classnames(styles.scrollScreen, active && styles.visible)}>
            <div key={key} className={classnames(className, active && styles.visible, styles[imageFormat])}/>
            <div className={innerImageStyle}>
                <div/>
            </div>
            <div className={styles.bottomContent}>
                <div className={styles.buttons}>
                    <div className={styles.playButton} onClick={setVideoPlaying.bind(null, true)}><Play/></div>
                    <div className={styles.mouseButton}><MouseSmall opacity={1}/></div>
                    <div className={styles.verticalLine}/>
                </div>
                <div className={styles.text}>
                    {boldTextWithPosition[0]}
                    {TEXTS[index].normal.map((row, i) => <h1 key={i} className={styles.normal}>{row}</h1>)}
                    {boldTextWithPosition[1]}
                </div>
                <div className={styles.mobileButton}>
                    <div onClick={setVideoPlaying.bind(null, true)} className={styles.button}><Play/></div>
                    <div className={styles.playText}><p>Play video</p></div>
                </div>
            </div>
        </div>);
}

export default Scroll01;
