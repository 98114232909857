import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import classnames from 'classnames';
import superagent from 'superagent';
import ReCAPTCHA from 'react-google-recaptcha';
import styles from './Scroll08.module.scss';
import {Letter} from '../../components/Icons';
import {TextArea, TextField} from '../../components/Form';
import validateField from '../../helpers/validateField';
import config from '../../config';
import {ContentContext} from '../../context/ContentContext';
import {Scrollbar} from '../../components/Scrollbar';
import withRouter from '../../helpers/withRouter';
import {CONTACT_US} from './navigationNames';

const PROCESSING_STATES = {
    NOT_TOUCHED: 0,
    SENDING: 1,
    SUCCESS: 2,
    ERROR_FETCHING_CAPTCHA_TOKEN: -1,
    ERROR_FETCHING_CAPTCHA_RESULT: -2,
    CAPTCHA_VALIDATE_FAILED: -3,
    ERROR_SENDING_MAIL: -4
};

const TEXT = {
    header: {
        thin: 'Any Questions?',
        bold: 'Contact Us'
    },
    email: 'contactus@teleportivity.com',
    address: {
        patent: 'International Patent PCT/AU2018/050263',
        designed: [
            'Designed by Inventrix Technologies Pty Ltd,',
            'Sydney, Australia',
            '(ABN: 15102817550)'
        ],
        powered: 'Powered by Teleportivity'
    }
};

const MAX_MESSAGE_SIZE = 2000;

function Scroll08({history}) {

    const [formSent, setFormSent] = useState(false);
    return (
        <div className={styles.container} id={CONTACT_US}>
            <div className={styles.header}>
                <div className={styles.top}>
                    <h1 className={styles.thin}>{TEXT.header.thin}</h1>
                    <h2 className={styles.bold}>{TEXT.header.bold}</h2>
                </div>
                <div className={styles.bottom}>
                    <div className={styles.email}>
                        <Letter/>
                        <a href={`mailto:${TEXT.email}`} target="_blank" rel='noopener'>{TEXT.email}</a>
                    </div>
                    <p className={styles.address}>{TEXT.address.patent}</p>
                    {TEXT.address.designed.map((str, i) =>
                        <p key={i} className={classnames(styles.close, styles.address)}>{str}</p>
                    )}
                    <p className={styles.terms} onClick={() => history.push('/terms-and-conditions')}>Terms and
                        Conditions</p>
                    <p className={styles.policy} onClick={() => history.push('/policy')}>Privacy Policy</p>
                    <p className={styles.address}>{TEXT.address.powered}</p>
                </div>
                <p className={styles.copyright}>© 2020, Teleportivity Inc</p>
            </div>
            {formSent ? <AfterSendLabel/> : <Form setFormSent={setFormSent}/>}
            <div className={styles.counter}/>

        </div>
    );
}

const tryToSend = async ({captchaRef}) => {
    captchaRef.current.execute();
    setTimeout(() => {
        const reCAPTCHAChallendge = document.querySelector('[title="recaptcha challenge"]');
        if (reCAPTCHAChallendge) {
            reCAPTCHAChallendge.parentElement.style.zIndex = 2147483001;
        }
    }, 100);
};


function AfterSendLabel() {
    return (
        <div className={styles.afterSendLabel}>
            <p>Thank you for your feedback!</p>
            <p>Your message was successfully sent</p>
        </div>
    );
}


const INITIAL_STATE = {
    fields: {name: '', email: '', msg: ''},
    errors: {
        name: {message: '', error: true},
        email: {message: '', error: true},
        msg: {message: '', error: true}
    }
};
function Form({setFormSent}) {
    const {setScrollBlocked} = useContext(ContentContext);
    const [sendMessageProcessing, setSendMessageProcessing] = useState(PROCESSING_STATES.NOT_TOUCHED);
    const [fields, setFields] = useState({...INITIAL_STATE.fields});
    const [errors, setErrors] = useState({...INITIAL_STATE.errors});
    const [token, setToken] = useState(null);

    useEffect(() => {
        if (sendMessageProcessing === PROCESSING_STATES.SUCCESS) setFormSent(true);
    }, [sendMessageProcessing]);

    const onFieldsChange = (field, event) => {
        if (field === 'msg' && event.target.value.length > MAX_MESSAGE_SIZE) event.target.value = event.target.value.substr(0, MAX_MESSAGE_SIZE);
        const updErrors = errors;
        const updFields = fields;
        const validateResult = validateField(field, event.target.value);
        updErrors[field] = validateResult
            ? {message: validateResult.errorMessage, error: true}
            : {message: '', error: false};
        updFields[field] = event.target.value;
        setErrors(updErrors);
        setFields({...updFields});
    };

    const validateCaptchaToken = response => {
        setSendMessageProcessing(PROCESSING_STATES.SENDING);
        superagent
            .get(`/api/verify?token=${response}` +
                `&name=${fields.name}&email=${fields.email}&body=${fields.msg}`)
            .set('Access-Control-Allow-Credentials', true)
            .set('Access-Control-Allow-Origin', '*')
            .withCredentials()
            .then(
                response => {
                    if (response.body.success) {
                        setSendMessageProcessing(PROCESSING_STATES.SUCCESS);
                    } else {
                        setSendMessageProcessing(PROCESSING_STATES.CAPTCHA_VALIDATE_FAILED);
                        console.log('Captcha validating was failed');
                    }
                })
            .catch(error => {
                setSendMessageProcessing(PROCESSING_STATES.ERROR_FETCHING_CAPTCHA_RESULT);
                console.log('Error while fetching captcha validating result: ', error);
            });
    };

    useEffect(() => {
        if (token) {
            validateCaptchaToken(token);
        }
    }, [token]);

    const captchaRef = useRef(null);

    const buttonContent = useMemo(() => {
        switch (sendMessageProcessing) {
            case PROCESSING_STATES.NOT_TOUCHED:
                return <div>Send</div>;
            case PROCESSING_STATES.SENDING:
                return <div>
                    <div>Sending</div>
                    <div className={styles.loader}/>
                </div>;
            case PROCESSING_STATES.SUCCESS:
                return <div>Success</div>;
            case PROCESSING_STATES.ERROR_FETCHING_CAPTCHA_RESULT:
            case PROCESSING_STATES.ERROR_FETCHING_CAPTCHA_TOKEN:
            case PROCESSING_STATES.ERROR_SENDING_MAIL:
                return <div>Send again</div>;
            default:
                return <div>Send</div>;
        }
    }, [sendMessageProcessing]);

    return (
        <div className={styles.form}>
            <div
                onMouseEnter={setScrollBlocked.bind(null, true)}
                onMouseLeave={setScrollBlocked.bind(null, false)}
                className={styles.marginContainer}
            >
                <Scrollbar
                    style={{height: '100%'}}
                    renderTrackHorizontal={props => <div {...props} className="track-horizontal"
                                                         style={{display: 'none'}}/>}
                    renderThumbHorizontal={props => <div {...props} className="thumb-horizontal"
                                                         style={{display: 'none'}}/>}
                    renderView={props => (<div {...props} style={{...props.style, overflowX: 'hidden'}}/>)}
                >
                    <TextField
                        value={fields.name}
                        onChange={onFieldsChange.bind(this, 'name')}
                        name={'name'}
                        error={errors.name.message}
                        label={'Your name'}
                        max={100}
                        controlled
                    />
                    <TextField
                        error={errors.email.message}
                        value={fields.email}
                        onChange={onFieldsChange.bind(null, 'email')}
                        name={'email'}
                        label={'Your email'}
                        max={100}
                        controlled
                    />
                    <TextArea
                        error={errors.msg.message}
                        value={fields.msg}
                        onChange={onFieldsChange.bind(null, 'msg')}
                        name={'msg'}
                        label={'Your message'}
                        controlled
                    />
                    <div className={styles.captcha}>
                        <ReCAPTCHA
                            size={'invisible'}
                            sitekey={config.KEYS.CAPTCHA_KEY_PUBLIC}
                            ref={captchaRef}
                            hl={'en'}
                            onChange={token => setToken(token)}
                            onExpired={() => setSendMessageProcessing(PROCESSING_STATES.NOT_TOUCHED)}
                            onErrored={() => setSendMessageProcessing(PROCESSING_STATES.NOT_TOUCHED)}
                        />
                    </div>
                </Scrollbar>
            </div>

            <button
                className={styles.button}
                onClick={() => tryToSend({setSendMessageProcessing, captchaRef})}
                disabled={Object.values(errors).some(field => field.error) || sendMessageProcessing === PROCESSING_STATES.SENDING}
            >
                {buttonContent}
            </button>

            <p className={styles.error}>
                {
                    [PROCESSING_STATES.ERROR_FETCHING_CAPTCHA_TOKEN,
                        PROCESSING_STATES.ERROR_SENDING_MAIL].indexOf(sendMessageProcessing) >= 0
                        ? 'There is a connection problem, please try again later'
                        : ' '
                }
            </p>
            <div className={styles.googleTerms}>
                <p>This site is protected by reCAPTCHA and the Google</p>
                <div>
                    <a target="_blank" rel='noopener' href="https://policies.google.com/privacy"> Privacy Policy</a>
                    <p>and</p>
                    <a target="_blank" rel='noopener' href="https://policies.google.com/terms"> Terms of Service</a>
                    <p>apply.</p>
                </div>
                <div className={styles.charCounter}>{MAX_MESSAGE_SIZE - fields.msg.length}</div>
            </div>
        </div>
    );
}

export default withRouter(Scroll08);
