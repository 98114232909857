import React from "react";
import { Scrollbars } from "react-custom-scrollbars";

export default function Scrollbar(props) {
    const { scrollbarRef, withoutZIndex, maxHeight, children, ...restProps } = props;
    return (
        <Scrollbars
            ref={scrollbarRef}
            {...restProps}
        >
            {children}
        </Scrollbars>
    );
}
