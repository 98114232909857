import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import HomePage from '../containers/HomePage';
import PrivacyPolicyPage from '../containers/PrivacyPolicyPage';
import Main from '../containers/Main';
import TermsAndConditionsPage from '../containers/TermsAndConditionsPage';
import NavigationContextContainer from '../context/NavigationContextContainer';

const routes = [
    <Route key={true} path="/policy" component={PrivacyPolicyPage}/>,
    <Route key={true} path="/terms-and-conditions" component={TermsAndConditionsPage}/>,
    <Route key={true} path="/" component={HomePage}/>,
    <Route component={HomePage}/>
];

export default function AppRouters() {
    return (
        <Router>
            <NavigationContextContainer>
                <Main>
                    <Switch>
                        {routes.map((Element, index) => <Element.type key={index} {...Element.props} />)}
                    </Switch>
                </Main>
            </NavigationContextContainer>
        </Router>
    );
}
