import React from "react";
import PropTypes from "prop-types";

Icon.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    activeColor: PropTypes.string,
    active: PropTypes.bool
};

Icon.defaultProps = {
    className: "",
    color: "var(--darkblue)",
    activeColor: "var(--white)",
    active: false
};

export default function Icon({color='var(--darkblue)', activeColor='var(--white)', className="", active=false}) {
    const actualColor = active ? activeColor : color;
    return (
        <svg className={className} width="32" height="32" viewBox="0 0 32 32" fill="none">
            <path
                d="M16 .6c-4.446 0-8.067 3.62-8.067 8.067v4.285C6.12 14.914 5 17.524 5 20.4c0 6.067 4.933 11 11 11s11-4.933 11-11c0-2.876-1.12-5.486-2.933-7.448V8.667C24.067 4.22 20.446.6 16 .6zm0 1.467c3.655 0 6.6 2.945 6.6 6.6v2.956A10.92 10.92 0 0016 9.4c-2.478 0-4.758.834-6.6 2.223V8.667c0-3.655 2.945-6.6 6.6-6.6zm0 8.8a9.522 9.522 0 019.533 9.533A9.522 9.522 0 0116 29.933 9.522 9.522 0 016.467 20.4 9.522 9.522 0 0116 10.867zm0 7.333a2.2 2.2 0 00-2.2 2.2c0 .954.616 1.759 1.467 2.063v1.604c0 .404.33.733.733.733.404 0 .733-.33.733-.733v-1.605A2.195 2.195 0 0018.2 20.4a2.2 2.2 0 00-2.2-2.2z"
                fill={actualColor}
                stroke={actualColor}
                strokeWidth="0.5"
            />
        </svg>
    );
}
