import React, {useContext, useEffect} from 'react';
import styles from './TermsAndConditionsPage.module.scss';
import {ContentContext} from '../context/ContentContext';
import {THEMES} from '../helpers/constants';

const TEXT = {
    title: ['Terms and ', 'Conditions']
};

function TermsAndConditionsPage() {
    const {setHeaderColor, setCurrentPageIndex, setHeaderType} = useContext(ContentContext);
    useEffect(() => {
        setHeaderColor(THEMES.DARK);
        setCurrentPageIndex(0);
        setHeaderType('reduceOnScroll');
        return () => {
            setHeaderType('');
        };
    }, []);

    return (
        <div>
            <div className={styles.container}>
                <h1 className={styles.title}>{TEXT.title[0]}<b>{TEXT.title[1]}</b></h1>            
                <iframe
                    src="/terms"
                    style={{
                        maxWidth: '100%',
                        width: '100%',
                        height: 'calc(100vh - 15.5rem)',
                        border: 'unset',
                    }}
                />
            </div>
        </div>
    );
}

export default TermsAndConditionsPage;
