import React from "react";
import PropTypes from "prop-types";

Play.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    opacity: PropTypes.number
};

Play.defaultProps = {
    className: "",
    color: "white",
    opacity: 1
};

export default function Play({color, className, opacity}) {
    return (
        <svg className={className} width="24" height="26" viewBox="0 0 24 26" fill="none">
            <path
                d="M1 0a1 1 0 00-1 1v24a1 1 0 001 1h1l21-12a1 1 0 001-1 1 1 0 00-1-1L2 0H1z"
                fill={color}
                opacity={opacity}
            />
        </svg>
    );
}
