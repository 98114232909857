import phone0 from "./scroll4_phone0.png";
import phone1 from "./scroll4_phone1.png";
import phone2 from "./scroll4_phone2.png";
import phone2_2x from "./scroll4_phone2_2x.png";
import phone2_3x from "./scroll4_phone2_3x.png";
import phone3 from "./scroll4_phone3.png";
import phone4 from "./scroll4_phone4.png";
import phone5 from "./scroll4_phone5.png";
import image0 from "./scroll02_0.png";
import image0_2x from "./scroll02_0_2x.png";
import image0_3x from "./scroll02_0_3x.png";
import image1 from "./scroll02_1.png";
import image2 from "./scroll02_2.png";

import phone0_05x from "./05x/scroll4_phone0.png";
import phone1_05x from "./05x/scroll4_phone1.png";
import phone2_05x from "./05x/scroll4_phone2.png";
import phone2_2x_05x from "./05x/scroll4_phone2_2x.png";
import phone2_3x_05x from "./05x/scroll4_phone2_3x.png";
import phone3_05x from "./05x/scroll4_phone3.png";
import phone4_05x from "./05x/scroll4_phone4.png";
import phone5_05x from "./05x/scroll4_phone5.png";
import image0_05x from "./05x/scroll02_0.png";
import image0_2x_05x from "./05x/scroll02_0_2x.png";
import image0_3x_05x from "./05x/scroll02_0_3x.png";
import image1_05x from "./05x/scroll02_1.png";
import image2_05x from "./05x/scroll02_2.png";


import demoQrCode from "./demoQrCode.png";
import demoQrCode_05x from "./05x/demoQrCode.png";
import {constraint05x, constraint1x} from '../../helpers/constants';

export const DEMO_QR_CODE = {
    src: demoQrCode,
    srcSet: `${demoQrCode_05x} ${constraint05x}, ${demoQrCode} ${constraint1x}`
};
export const SCROLL2_IMAGES = [
    {src: image0, srcSet: `${image0} 480w, ${image0_2x} 820w, ${image0_3x} 1200w`},
    {src: image1, srcSet: `${image1_05x} ${constraint05x}, ${image1} ${constraint1x}`},
    {src: image2, srcSet: `${image2_05x} ${constraint05x}, ${image2} ${constraint1x}`},
    {src: phone0, srcSet: `${phone0_05x} ${constraint05x}, ${phone0} ${constraint1x}`}
];

export const SCROLL4_MOBILE = [
    {src:phone0, srcSet: `${phone0_05x} ${constraint05x}, ${phone0} ${constraint1x}`},
    {src:phone1, srcSet: `${phone1_05x} ${constraint05x}, ${phone1} ${constraint1x}`},
    {src:phone2, srcSet: `${phone2_2x} 2x, ${phone2_3x} 3x`},
    {src:phone3, srcSet: `${phone3_05x} ${constraint05x}, ${phone3} ${constraint1x}`},
    {src:phone4, srcSet: `${phone4_05x} ${constraint05x}, ${phone4} ${constraint1x}`},
    {src:phone5, srcSet: `${phone5_05x} ${constraint05x}, ${phone5} ${constraint1x}`},
];

