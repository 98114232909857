import React, {useEffect, useRef, useState} from 'react';
import styles from './Scroll03.module.scss';
import HorizontalNavigationBar from '../../components/HorizontalNavigationBar/HorizontalNavigationBar';
import CarouselComponent from '../../components/CarouselComponent/CarouselComponent';
import {Swipeable} from 'react-swipeable';
import SCROLL3_IMAGES from '../../assets/images/scroll3_images';

const CONTENT = [
    {
        index: 1,
        thin: 'Fixed Teleportal™',
        bold: 'Video Concierge.',
        plain: 'Teleportivity lets you scale, push and pull your very best staff where they’re required; control high traffic locations; and provide a constant, seamless customer experience, perfectly complementing our mobile platform to create a holistic, unparalleled customer experience.',
        subContent: [
            {title: 'Just Tap for assistance'},
            {title: 'Instantly Access a Video Concierge'},
            {title: 'On-Demand Assistance from the support team'}
        ]
    },
    {
        index: 2,
        thin: 'Be ',
        bold: 'everywhere!',
        plain: 'If you need to jump from the fixed help-desk screen to your customers smartphone, do so in seconds! Simply present a QR code or web link and "jump" straight to your customer\'s device! (No app required)',
        subContent: [
            {title: 'Scan QR with your smartphone'},
            {title: 'Continue consultation anywhere you need it'}]
    },
    {
        index: 3,
        thin: 'Patent pending ',
        bold: 'Jump Screen technology',
        plain: 'Teleportivity\'s patented "jump" screen technology allows your support staff to proactively jump "in" and "out" of screens, allowing full movement between screens and locations seamlessly.',
        subContent: [
            {title: 'Jump screen technology'}]
    }
];

function formImageAttributes(index) {
    if(isNaN(index)) return {};
    return {
        src: SCROLL3_IMAGES[index] ? SCROLL3_IMAGES[index].src : null,
        srcSet: SCROLL3_IMAGES[index] ? SCROLL3_IMAGES[index].srcSet : null,
        alt: 'image',
        draggable: false
    };
}

const TABS_COUNT = 3;
const JUMP_SCREEN_COUNT = 4;

function Scroll03({}) {
    const [selectedTab, setSelectedTab] = useState(0);
    const [activeSubTab, setActiveSubTab] = useState(0);
    const [activeJumpScreen, setActiveJumpScreen] = useState(0);
    const [isBackWard, setIsBackWard] = useState(false);
    const timer = useRef(null);
    const subContent = CONTENT[selectedTab].subContent || [];

    const cleareTimer = () => {
        if (timer.current) {
            clearTimeout(timer.current);
        }
    };

    const setTimer = () => {
        cleareTimer();
        timer.current = setTimeout(() => setActiveSubTab((activeSubTab + 1) % subContent.length || 0), 3000);
    };

    useEffect(() => {
        setActiveSubTab(0);
    }, [selectedTab]);

    useEffect(() => {
        setTimer();
        return () => clearTimeout(timer.current);
    }, [activeSubTab, selectedTab]);
    useEffect(() => {
        const timer = setTimeout(() => {
            const newValue = Math.floor(Math.random() * JUMP_SCREEN_COUNT);
            setActiveJumpScreen(newValue === activeJumpScreen ? (newValue + 1) % JUMP_SCREEN_COUNT : newValue);
        }, 3000);
        return () => clearTimeout(timer);
    }, [activeJumpScreen]);

    const onClickLeft = () => {
        cleareTimer();
        setSelectedTab((TABS_COUNT + selectedTab - 1) % TABS_COUNT);
        setIsBackWard(true);
    };
    const onClickRight = () => {
        cleareTimer();
        setSelectedTab((selectedTab + 1) % TABS_COUNT);
        setIsBackWard(false);
    };

    const onClickIndex = (index) => {
        cleareTimer();
        setIsBackWard(selectedTab > index);
        setSelectedTab((index) % TABS_COUNT);
    };

    return <div className={styles.container}>
        <div className={styles.textWithNavigation}>
            <CarouselComponent activeIndex={selectedTab} count={TABS_COUNT} isBackWard={isBackWard}>
                {CONTENT.map((el, i) => {
                    return (
                        <div key={i} className={styles.text}>
                            <div className={styles.header}>
                                <h1>{el.thin || ''}</h1>
                                <h2>{el.bold}</h2>
                            </div>
                            <div className={styles.plainText}>
                                {el.plain}
                            </div>
                        </div>
                    );
                })}
            </CarouselComponent>
            <div className={styles.navigationContainer}>
                <HorizontalNavigationBar count={TABS_COUNT}
                                         activeIndex={selectedTab}
                                         onClickLeft={onClickLeft}
                                         onClickRight={onClickRight}
                                         onClickIndex={onClickIndex}/>
                <CarouselComponent activeIndex={activeSubTab}>
                    {subContent.map((el, i) => {
                        return <div key={`navigationTitle${i}`} className={styles.navigationTitle}>
                            {el.title}
                        </div>;
                    })}
                </CarouselComponent>
            </div>
        </div>
        <Swipeable className={styles.images} onSwipedRight={onClickLeft} onSwipedLeft={onClickRight}>
            {selectedTab === 0 && <CarouselComponent activeIndex={activeSubTab}>
                <div key={`image0`} className={`${styles.imageContainer}`}>
                    <img {...formImageAttributes(0)}/>
                </div>
                <div key={`image1`} className={`${styles.imageContainer}`}>
                    <img {...formImageAttributes(1)}/>
                </div>
                <div key={`image2`} className={`${styles.imageContainer}`}>
                    <img {...formImageAttributes(2)}/>
                </div>
            </CarouselComponent>}
            {selectedTab === 1 && <CarouselComponent activeIndex={activeSubTab}>
                <div key={`image3`} className={`${styles.imageContainer}`}>
                    <img {...formImageAttributes(3)}/>
                </div>
                <div key={`image4`} className={`${styles.imageContainer}`}>
                    <img {...formImageAttributes(4)}/>
                </div>
            </CarouselComponent>}
            {selectedTab === 2 && <div className={styles.jumpScreenContainer}>
                <div key={`image5`} className={`${styles.jumpScreen} ${styles.imageContainer}`}>
                    {SCROLL3_IMAGES[5] &&
                    <img {...formImageAttributes(5)}/>}
                </div>
                <div key={`image5_${activeJumpScreen}`}
                     className={`${styles.jumpScreen} ${styles.imageContainer} ${styles.appear}`}>
                    <img {...formImageAttributes(6 + activeJumpScreen)}/>
                </div>
            </div>}

        </Swipeable>
    </div>;
}

export default Scroll03;
