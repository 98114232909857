import React, {useContext} from 'react';
import classnames from 'classnames';
import styles from './DemoQrCode.module.scss';
import {Close} from '../Icons';
import {ContentContext} from '../../context/ContentContext';
import Apple from '../Icons/Apple';
import Android from '../Icons/Android';
import Web from '../Icons/Web';
import {DEMO_QR_CODE} from '../../assets/images';

function DemoQrCode() {
    const {showDemoQrCode: active, setShowDemoQrCode} = useContext(ContentContext);

    return (
        <div className={classnames(styles.container, active && styles.active)}>
            <div className={styles.content}>
                <div className={styles.text}>
                    <h1>Scan Qr code to access</h1>
                    <h2>demo site</h2>
                </div>
                <div className={styles.qrBlock}>
                    <div className={styles.qrBlockText}>
                        <div className={styles.title}>
                            Room instructions &
                            <br/>
                            Live Assistance
                        </div>
                        <div className={styles.grey}>
                            Web browser Compatibility
                            <br/>
                            Safari for iOS
                            <br/>
                            Chrome for Android
                        </div>
                        <div className={styles.linkContainer}>
                            Go to <a href={'https://qrideo.me/help/me'} target="_blank"  rel='noopener'>qrideo.me/help/me</a>
                        </div>
                    </div>
                    <div className={styles.qrImage}>
                        <img src={DEMO_QR_CODE.src} srcSet={DEMO_QR_CODE.srcSet}/>
                    </div>
                </div>

                <div className={styles.instructions}>
                    <div>
                        <Apple/>
                        <span>Place camera over code</span>
                    </div>
                    <div>
                        <Android/>
                        <span>Use QR Reader</span>
                    </div>
                    <div>
                        <Web/>
                        <span>Enter link into safari/chrome</span>
                    </div>
                </div>
            </div>

            <button className={styles.closeButton} onClick={() => setShowDemoQrCode(false)}>
                <Close/>
            </button>
        </div>
    );
}

export default DemoQrCode;
