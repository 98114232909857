/* global Modernizr */
import React from 'react';
import {THEMES} from '../helpers/constants';

export const ContentContext = React.createContext();
const COLORS = [
    THEMES.DARK,
    THEMES.LIGHT,
    THEMES.DARK,
    THEMES.DARK,
    THEMES.DARK,
    THEMES.LIGHT,
    THEMES.LIGHT,
    THEMES.DARK,
    THEMES.DARK];

export default class ContentContextContainer extends React.Component {
    constructor(props) {
        super(props);
        let imageFormat = 'jpg';
        if (Modernizr.webp) {
            imageFormat = 'webp';
        } else if (Modernizr['jpeg2000']) {
            imageFormat = 'jpeg2000';
        }

        this.state = {
            imageFormat,
            isMobileLayout: false,
            scrollBlocked: false,
            showMenu: false,
            isVideoPlaying: false,
            showDemoQrCode: false,
            headerColor: THEMES.LIGHT,
            currentPageIndex: 1,
            scrollerRef: null,
            headerType: ''
        };
        this.funcs = {
            setVideoPlaying: this.setVideoPlaying,
            setShowMenu: this.setShowMenu,
            setHeaderType: this.setHeaderType,
            setShowDemoQrCode: this.setShowDemoQrCode,
            setHeaderColor: this.setHeaderColor,
            setCurrentPageIndex: this.setCurrentPageIndex,
            pageOnChange: this.pageOnChange,
            setScrollerRef: this.setScrollerRef,
            setScrollBlocked: this.setScrollBlocked,
            enableMobileLayout: this.enableMobileLayout

        };
        window.cc = this;
    }

    setScrollBlocked = scrollBlocked => this.setState({scrollBlocked});
    setVideoPlaying = isVideoPlaying => this.setState({isVideoPlaying, scrollBlocked: isVideoPlaying});
    setHeaderColor = headerColor => this.setState({headerColor});
    setHeaderType = headerType => this.setState({headerType});
    setCurrentPageIndex = currentPageIndex => this.setState({currentPageIndex});
    setShowMenu = showMenu => this.setState({showMenu});
    setShowDemoQrCode = showDemoQrCode => this.setState({showDemoQrCode});
    setScrollerRef = scrollerRef => this.setState({scrollerRef});
    enableMobileLayout = state => {
        this.setState({
            isMobileLayout: state
        });
        if (state) {
            this.setState({
                currentPageIndex: 1,
                headerColor: 'light'
            });
        }
    };

    pageOnChange = newPosition => {
        this.setHeaderColor(COLORS[newPosition]);
        this.setCurrentPageIndex(newPosition);
    };

    render() {
        return (
            <ContentContext.Provider value={{...this.state, ...this.funcs}}>
                {this.props.children}
            </ContentContext.Provider>
        );
    }
}
