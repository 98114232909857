import React, {useContext, useEffect, useRef, useState} from 'react';
import {useWindowSize} from 'react-use';
import classnames from 'classnames';
import Header from './Header';
import styles from './Main.module.scss';
import Menu from '../components/Menu/Menu';
import PageCounter from '../components/PageCounter/PageCounter';
import {ContentContext} from '../context/ContentContext';
import DemoQrCode from '../components/DemoQrCode/DemoQrCode';
import Youtube from 'react-youtube';
import {Close} from '../components/Icons';
import {MOBILE_SIZE_EDGE} from '../helpers/constants';

const YOUTUBE_OPTIONS = {
    height: '560',
    width: '968',
    playerVars: {
        autoplay: 1
    }
};
const MOBILE_YOUTUBE_OPTIONS = {
    height: '200',
    width: '300',
    playerVars: {
        autoplay: 1
    }
};

function Main(props) {
    const {showMenu, showDemoQrCode, currentPageIndex, setScrollerRef, isVideoPlaying, enableMobileLayout} = useContext(ContentContext);
    const [backgroundStyle, setBackgroundStyle] = useState(styles[`back_1`]);
    const scrollerRef = useRef(null);
    useEffect(() => {
        setBackgroundStyle(styles[`static_${currentPageIndex}`]);
    }, [currentPageIndex]);
    useEffect(() => {
        setScrollerRef(scrollerRef);
    }, []);

    const {width} = useWindowSize();
    useEffect(()=>{
        if(width <= MOBILE_SIZE_EDGE) {
            enableMobileLayout(true);
        } else {
            enableMobileLayout(false);
        }
    },[width]);

    return (
        <div
            className={classnames(styles.mainContainer, backgroundStyle, (showMenu || showDemoQrCode) && styles.darken, showDemoQrCode && styles.blur)}>
            <Header/>
            <Menu/>
            <YoutubeContainer/>
            <DemoQrCode/>
            <PageCounter
                size={8}
                goToPage={n => scrollerRef.current.goToPage(n)}
            />
            <div className={classnames(styles.contentContainer, (showMenu || isVideoPlaying) && styles.blur)}>
                {props.children}
            </div>
        </div>
    );
}

function YoutubeContainer() {
    const {setVideoPlaying, isVideoPlaying, isMobileLayout} = useContext(ContentContext);
    const [showYoutube, setShowYouTube] = useState(false);
    useEffect(() => {
        if (isVideoPlaying) setShowYouTube(true);
        if (!isVideoPlaying) setTimeout(() => setShowYouTube(false), 500);
    }, [isVideoPlaying]);
    return (
        <div className={classnames(styles.player, !isVideoPlaying && styles.hidden)}>
            {showYoutube &&
            <Youtube videoId='E9c6KiqWGYU' opts={isMobileLayout ? MOBILE_YOUTUBE_OPTIONS : YOUTUBE_OPTIONS}/>}
            <div className={styles.close} onClick={setVideoPlaying.bind(null, false)}><Close/></div>
        </div>
    );
}

export default Main;
