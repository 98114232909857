import React from "react";

function Web() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" height="24" width="24">
            <path
                fill="#fff"
                stroke="#fff"
                d="M12 2c-1 0-3 2-3 5h6c0-3-2-5-3-5zM8 3a10 10 0 00-5 4h4l1-4zm8 0l1 4h4a10 10 0 00-5-4zM2 9a10 10 0 000 6h4a25 25 0 010-3V9H2zm6 0a22 22 0 000 3v3h8V9H8zm10 0v6h4v-3a10 10 0 000-3h-4zM3 17a10 10 0 005 4l-1-4H3zm6 0c0 3 2 5 3 5s3-2 3-5H9zm8 0l-1 4a10 10 0 005-4h-4z"
                opacity="0.5"
            />
        </svg>
    );
}

export default Web;
