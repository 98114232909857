export const TEXT = {
    title: ['Privacy ', 'Policy'],
    introduce:
        `This is the Privacy Policy applying to the users of Teleportivity. This Privacy Policy is issued under the General Data Protection Regulation and applies to everyone in the European Union. It is also issued under the Australian Privacy Act and applies to everyone in Australia and the United States. This Privacy Policy applies to personal data we collect and process. Personal data is information relating to an identifiable person.`,
    blocks: [
        {
            title: 'Who are we?',
            body: [
                {text: 'We refers to the Teleportivity Group - comprising Teleportivity, Inc (a US Company) and Teleportivity Pty Ltd (an Australian Company - ACN: 620 019 150)'}
            ]
        },
        {
            title: 'We are both a Data Controller and a Data Processor.',
            grey: true,
            body: [
                {
                    subtitle: 'Data Controller',
                    text:
                        'We are the data controller for the personal data you give us when we provide our services to you.\nWe also collect personal data from you if you send us a request via our website and when you are browsing our website. We are the Data Controller for that personal data.'
                },
                {
                    subtitle: 'Data Processor',
                    text: 'We are the data processor for the personal data collected by our clients when they use out software.\nIn these cases, our clients are the data controllers.'
                }
            ]
        },
        {
            body: [
                {
                    subtitle: 'What information do we collect?',
                    text: 'If you sign up to Teleportivity we will collect your name, email address, and phone number.\n' +
                        'If you are browsing our website, we collect information about your browsing session.\n' +
                        'This includes the browser type, language setting, referring site, any additional websites, and the date and time of each visit. We will also collect your Internet Protocol (IP) address.'
                },
                {
                    subtitle: 'What information do we process?',
                    text: 'We process personal data collected by you if you have entered into an agreement with us to use our website and software.\n' +
                        'This processing is subject to a processing agreement. We do not process data except if it is data we control or on instructions from the data controller.'
                }
            ]
        },
        {
            body: [
                {
                    subtitle: 'How we collect your information ',
                    text: 'We collect information directly from you. \n' +
                        'We also collect personal information when you access our website and software and when we provide our services to you.\n' +
                        'We may receive personal information from third parties. If we do, we will protect it as set out in this Privacy Policy.\n' +
                        'We collect Cookies. These are very small files which a website uses to identify you when you come back to the site and to store details about your use of the site.'
                },
                {
                    subtitle: 'Why we collect this information – the legal basis for collecting this information?',
                    text: 'We collect personal data from you because it is necessary for the performance of the contract between you and us.\n' +
                        'We collect personal data from you because you consent. You are able to remove your consent at any time. You can do this by contacting contactus@teleportivity.com.'
                }
            ]
        },
        {
            body: [
                {
                    subtitle: 'Why we collect this information – the purpose for collecting this information',
                    text: 'We will use this information to provide our services. We will also use this data to verify your identity, monitor suspicious or fraudulent activity, and to identify violations of our service polices.\n' +
                        'With your consent, we may use personal data for specific purposes that are separate from any contract between you and us. When we ask you consent, we will provide you with information about why we are collecting this data. You may choose whether to consent or not.'
                },
                {
                    subtitle: 'How we store your data',
                    text: 'All data you provide us is stored in secure hosting facilities at AWS (Amazon Web Services) in Australia, Europe and/or United States.\n' +
                        'Unless we are required by law to store your data, we will stop processing and delete it once we no longer require it.'
                }
            ]
        },
        {
            title: 'Your Rights',
            body: [
                {
                    text: 'If we process your personal data, you have the rights listed below. For information that we control, we will facilitate your rights. For information that we process, we will assist the data controller to facilitate your rights. We will respond to any requests concerning your rights without undue delay.'
                },
                {
                    text: 'We will not charge for any request, unless the request is obviously unfounded, or excessive. In the exercise of your rights, we may need to take steps to verify your identity.'
                }
            ]
        },
        {
            grey: true,
            body: [
                {
                    subtitle: 'Right to information',
                    text: 'You have a right to information regarding the purposes and nature of the processing we undertake.'
                },
                {
                    subtitle: 'Right to access.',
                    text: 'You can access the personal data about that we control and process.'
                }
            ]
        },
        {
            grey: true,
            body: [
                {
                    subtitle: 'Right to correction.',
                    text: 'If you ask us to, we will correct any information that we hold about you that is incorrect.'
                },
                {
                    subtitle: 'Right to restrict processing.',
                    text: 'You can ask us to temporarily stop processing your personal data. You can ask us not to process your personal data for some particular purpose.'
                }
            ]
        },
        {
            grey: true,
            body: [
                {
                    subtitle: 'Right to deletion of your data.',
                    text: 'Unless we are required by law to hold your data, you may request that we delete it.\n' + 'In any event, unless we are required by law to hold your personal data, we will delete it when we stop providing the service under the contract.'
                },
                {
                    subtitle: 'Portability of your data.',
                    text: 'You can ask us to give you your personal data in a Form that allows it to be read by other data controllers of processors. We will provide the data in a structured, commonly used and machine-readable format.'
                }
            ]
        },
        {
            grey: true,
            withLinks: true,
            body: [
                {
                    subtitle: 'Right to complain to the Supervisory Authority',
                    text: [
                        {
                            text: 'You have the right to complain to the data protection Supervisory Authority in your country.\nIf you are in the EU, you can find contact details for your Supervisory Authority here:',
                            link: 'https://edpb.europa.eu/about-edpb/board/members_en'
                        },

                        {

                            text: 'If you are in Australia, the website for the Office Australian Information Commissioner is here:',
                            link: 'https://www.oaic.gov.au/privacy/privacy-complaints/'
                        }
                    ]
                }
            ]
        },
        {
            title: 'Personal Data Transfers to other countries',
            body: [
                {
                    text: 'IF YOU ARE IN THE EU:\nWhere we are data processors, we will only transfer your personal data out of the EU in accordance with our data processing agreement and standard data protection clauses approved by the Commission or a Supervisory Authority'
                }
            ]
        },
        {
            body: [
                {
                    text: 'IF YOU ARE IN AUSTRALIA/UNITED STATES:\nBy registering as a user of and accessing our Site, you agree:\n\n' +
                        'to and authorise us giving your personal information, in accordance with this Privacy Policy, to a third party who is not in Australia or a third party who may transfer and store outside Australia your personal information (Overseas Recipient); \n\n' +
                        'that, while we will take reasonable steps to ensure that an Overseas Recipient does not breach the APPs, Overseas Recipients are not bound by the APPs and subclause 8.1 of the APPs does not apply to the disclosure of your personal information to an Overseas Recipient; and that, if an Overseas Recipient handles your information in breach of the APPs, you will not be able to seek redress under the Act.'
                }
            ]
        },
        {
            title: 'How we secure your personal data',
            body: [
                {
                    text: 'Personal Data that we control is stored in secured servers provided by AWS. We have in place Technical and Organisational measures to secure your personal data.\n' +
                        'Passwords are stored via a [one-way salted hash algorithm]'
                }
            ]
        }
    ]
};
