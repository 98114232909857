import React, {useContext} from 'react';
import {ContentContext} from './ContentContext';
import withRouter from '../helpers/withRouter';
import {HOME} from '../containers/ScrollPages/navigationNames';

export const NavigationContext = React.createContext();

class NavigationContextContainerComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
        this.funcs = {
            goHome: this.goHome,
            goTo: this.goTo,
            goToHref: this.goToHref,
        };
    }

    goTo = (path) => {
        this.props.history.push(path);
    };
    goHome = () => {
        this.props.history.push('/');
        if (this.props.content.isMobileLayout) {
            window.location.href=`#${HOME}`;
        } else {
            this.props.content.scrollerRef.current
            && this.props.content.scrollerRef.current.goToPage(0);
        }
    };

    goToHref = (href) => {
            window.location.href=`#${href}`;
    };

    render() {
        return (
            <NavigationContext.Provider value={{...this.state, ...this.funcs}}>
                {this.props.children}
            </NavigationContext.Provider>
        );
    }
}


function NavigationContextContainer(props) {
    const context = useContext(ContentContext);
    return <NavigationContextContainerComponent {...props} content={context}/>
}

export default withRouter(NavigationContextContainer);
