import React from 'react';
import PropTypes from "prop-types";
import classnames from 'classnames';
import styles from './TextWithIcon.module.scss';

TextWithIcon.propTypes = {
    active: PropTypes.bool,
    icon: PropTypes.func,
    text: PropTypes.array
};

TextWithIcon.defaultProps = {
    active: false,
    text: []
};

export default function TextWithIcon({active, icon, text, onHover, className}) {
    const containerClassname = classnames(styles.container, className);
    return (
        <div className={containerClassname}>
            <div className={classnames(styles.iconContainer, active && styles.active)} onMouseEnter={onHover}>
                <div className={styles.icon}>
                    {icon({active})}
                </div>
            </div>
            <div className={styles.textContainer}>
                {text.map((row,i) => <p key={`textWithIcon${i}`} className={active ? styles.active : ''}>{row}</p>)}
            </div>
        </div>
    );
}
