import React, {useState} from 'react';
import styles from './Scroll04Mobile.module.scss';
import CarouselComponent from '../../../components/CarouselComponent/CarouselComponent';
import HorizontalNavigationBar from '../../../components/HorizontalNavigationBar/HorizontalNavigationBar';
import {SCROLL4_MOBILE} from '../../../assets/images';

const TEXT = {
    title: `We're making every space, place and object a portal to a world of help.`,
    icons: {
        support: 'Instant Remote Visual Support',
        signpost: 'Wayfinding',
        message: 'Managed Services',
        settings: 'Asset Management',
        info: 'Document Repository',
        lock: 'Facilities Support'
    }
};

const TEXTS = [TEXT.icons.support,
    TEXT.icons.signpost,
    TEXT.icons.message,
    TEXT.icons.settings,
    TEXT.icons.info,
    TEXT.icons.lock
];

const COUNT = TEXTS.length;

function formImageAttributes(index) {
    if(isNaN(index)) return {};
    return {
        src: SCROLL4_MOBILE[index] ? SCROLL4_MOBILE[index].src : null,
        srcSet: SCROLL4_MOBILE[index] ? SCROLL4_MOBILE[index].srcSet : null,
        alt: 'image',
        draggable: false
    };
}


function Scroll04Mobile() {
    const [selectedTab, setSelectedTab] = useState(0);
    const [isBackWard, setIsBackWard] = useState(false);

    const onClickIndex = (index) => {
        setIsBackWard(selectedTab > index);
        setSelectedTab(index % COUNT);
    };

    const onClickLeft = () => {
        setSelectedTab((COUNT + selectedTab - 1) % COUNT);
        setIsBackWard(true);
    };
    const onClickRight = () => {
        setSelectedTab((selectedTab + 1) % COUNT);
        setIsBackWard(false);
    };

    return (
        <div className={styles.container}>
            <div className={styles.text}>
                {TEXT.title}
            </div>
            <div className={styles.carouselContainer}>
                <CarouselComponent isBackWard={isBackWard} activeIndex={selectedTab} onSwipedRight={onClickLeft} onSwipedLeft={onClickRight}>
                    <div className={`${styles.imageContainer}`}>
                        <img {...formImageAttributes(0)}/>
                    </div>
                    <div className={`${styles.imageContainer}`}>
                        <img {...formImageAttributes(1)}/>
                    </div>
                    <div className={`${styles.imageContainer}`}>
                        <img {...formImageAttributes(2)}/>
                    </div>
                    <div className={`${styles.imageContainer}`}>
                        <img {...formImageAttributes(3)}/>
                    </div>
                    <div className={`${styles.imageContainer}`}>
                        <img {...formImageAttributes(4)}/>
                    </div>
                    <div className={`${styles.imageContainer}`}>
                        <img {...formImageAttributes(5)}/>
                    </div>
                </CarouselComponent>
            </div>
            <HorizontalNavigationBar activeIndex={selectedTab}
                                     count={COUNT}
                                     onClickIndex={onClickIndex}/>
            <div className={styles.textCarouselContainer}>
                <CarouselComponent isBackWard={isBackWard} activeIndex={selectedTab}>
                    {TEXTS.map((text, index) =>
                        <div key={index}>{text}</div>
                    )}
                </CarouselComponent>
            </div>
        </div>
    );
}

export default Scroll04Mobile;
