import React, {useState, useContext} from 'react';
import styles from './Scroll02.module.scss';
import {SCROLL2_IMAGES} from '../../assets/images';
import CarouselComponent from '../../components/CarouselComponent/CarouselComponent';
import HorizontalNavigationBar from '../../components/HorizontalNavigationBar/HorizontalNavigationBar';
import TryNowIcon from '../../components/Icons/TryNowIcon';
import {ContentContext} from '../../context/ContentContext';

const IMAGES_COUNT = 4;

function formImageAttributes(index) {
    if(isNaN(index)) return {};
    return {
        src: SCROLL2_IMAGES[index] ? SCROLL2_IMAGES[index].src : null,
        srcSet: SCROLL2_IMAGES[index] ? SCROLL2_IMAGES[index].srcSet : null,
        alt: 'image',
        draggable: false
    };
}

function Scroll02() {
    const {setShowDemoQrCode} = useContext(ContentContext);
    const [selectedTab, setSelectedTab] = useState(0);
    const [isBackWard, setIsBackWard] = useState(false);
    const onClickLeft = () => {
        setSelectedTab((IMAGES_COUNT + selectedTab - 1) % IMAGES_COUNT);
        setIsBackWard(true);
    };
    const onClickIndex = (index) => {
        setIsBackWard(selectedTab > index);
        setSelectedTab((index) % IMAGES_COUNT);
    };
    const onClickRight = () => {
        setSelectedTab((selectedTab + 1) % IMAGES_COUNT);
        setIsBackWard(false);
    };
    return (
        <div className={styles.container}>
            <div className={styles.text}>
                <div className={styles.title}>
                    <h1>Create thousands <br/>of customized Live <br/>Video & Information<div className={styles.hidden}> Help Points</div></h1>
                    <h2> Help Points</h2>
                </div>
                <div className={styles.benefits}>
                    <div>No app required</div>
                    <div>Easy to use</div>
                    <div>Qr/NFC/Web links</div>
                    <div>Deploy anywhere</div>
                    <div>Web browser compatibility</div>
                </div>
                <button className={`${styles.tryNow} ${styles.desktop}`} onClick={() => setShowDemoQrCode(true)}>
                    <TryNowIcon/>
                    <span>Try now</span>
                </button>
            </div>
            <div className={styles.rightBlock}>
                <div className={styles.presentationBlock}>
                    <div className={styles.carouselContainer}>
                        <CarouselComponent isBackWard={isBackWard} activeIndex={selectedTab} onSwipedRight={onClickLeft} onSwipedLeft={onClickRight}>
                            <div className={styles.imageContainer}>
                                <img className={styles.image0} {...formImageAttributes(0)}/>
                            </div>
                            <div className={styles.imageContainer}>
                                <img {...formImageAttributes(1)}/>
                            </div>
                            <div className={styles.imageContainer}>
                                <img {...formImageAttributes(2)}/>
                            </div>
                            <div className={styles.imageContainer}>
                                <img {...formImageAttributes(3)}/>
                            </div>
                        </CarouselComponent>
                    </div>
                    <div className={styles.bottomPart}>
                        <HorizontalNavigationBar className={styles.navigationBar}
                                                 count={IMAGES_COUNT}
                                                 activeIndex={selectedTab}
                                                 onClickLeft={onClickLeft}
                                                 onClickIndex={onClickIndex}
                                                 onClickRight={onClickRight}/>
                        <div className={styles.textCarouselContainer}>
                            <CarouselComponent isBackWard={isBackWard} activeIndex={selectedTab}>
                                <div>
                                    Help Points can be activated by scanning a QR Code, tapping NFC, or a Web-Link.
                                </div>
                                <div>
                                    Scan QR Code / Tap NFC / Web-Link in Browser
                                </div>
                                <div>
                                    Access a unique mini-site attached to each help point
                                </div>
                                <div>
                                    Instant video chat with support team
                                </div>
                            </CarouselComponent>
                        </div>
                    </div>
                </div>
                <button className={`${styles.tryNow} ${styles.mobile}`} onClick={() => setShowDemoQrCode(true)}>
                    <TryNowIcon/>
                    <span>Try now</span>
                </button>
            </div>
        </div>
    );
}

export default Scroll02;
