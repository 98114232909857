import React, {useContext} from "react";
import classnames from 'classnames';
import styles from './PageCounter.module.scss';
import {MouseSmall} from "../Icons";
import {ContentContext} from "../../context/ContentContext";
import {THEMES} from "../../helpers/constants";

function PageCounter({size, goToPage}) {
    const {headerColor: color, showMenu: blur, currentPageIndex: actual} = useContext(ContentContext);
    const arr = [];
    for (let i = 1; i <= size; i++) arr.push(i);
    const actualStyle = color === THEMES.LIGHT ? styles.light : styles.dark;
    return (
        <div className={classnames(styles.container, actual < 2 && styles.invisible, blur && styles.blur)}>
            <div className={styles.serifContainer}>
                {arr.map(x =>
                    <div className={styles.clickArea} onClick={() => goToPage(x - 1)} key={`serif_${x}`}>
                        <div className={classnames(styles.serif, actual === x && actualStyle)} />
                    </div>
                )}
            </div>
            <MouseSmall className={classnames(styles.mouse, actualStyle)}/>
        </div>
    );
}

export default PageCounter;
