import React from "react";
import PropTypes from "prop-types";

Icon.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    activeColor: PropTypes.string,
    active: PropTypes.bool
};

Icon.defaultProps = {
    className: "",
    color: "var(--darkblue)",
    activeColor: "var(--white)",
    active: false
};

export default function Icon({color='var(--darkblue)', activeColor='var(--white)', className="", active=false}) {
    const actualColor = active ? activeColor : color;
    return (
        <svg className={className} width="32" height="32" viewBox="0 0 32 32" fill="none">
            <path
                fill={actualColor}
                stroke={actualColor}
                strokeWidth="0.5"
                d="M14 1a1 1 0 000 1l-1 3a11 11 0 00-2 1L8 4a1 1 0 00-1 0L4 7l2 4a11 11 0 00-1 2l-3 1a1 1 0 00-1 0v4a1 1 0 001 0l3 1 1 2-2 3a1 1 0 000 1l3 3 4-2 2 1 1 3a1 1 0 000 1h4a1 1 0 000-1l1-3a11 11 0 002-1l4 2 3-3a1 1 0 000-1l-2-3 1-2 3-1a1 1 0 001 0v-4a1 1 0 00-1 0l-3-1a11 11 0 00-1-2l2-4-3-3a1 1 0 00-1 0l-3 2-2-1-1-3a1 1 0 000-1h-4zm1 1h2l1 4a1 1 0 000 1l3 1a1 1 0 001 0l3-3 2 2-3 3a1 1 0 000 1l1 3a1 1 0 001 0l4 1v2l-4 1a1 1 0 00-1 0 10 10 0 01-1 3 1 1 0 000 1l3 3-2 2-3-3a1 1 0 00-1 0 10 10 0 01-3 1 1 1 0 000 1l-1 4h-2l-1-4a1 1 0 000-1 10 10 0 01-3-1 1 1 0 00-1 0l-3 3-2-2 3-3a1 1 0 000-1 10 10 0 01-2-3l-4-1v-2l4-1a1 1 0 001 0 10 10 0 011-3 1 1 0 000-1L5 7l2-2 3 3a1 1 0 001 0 10 10 0 013-1 1 1 0 000-1l1-4zm1 9a5 5 0 00-5 5 5 5 0 005 5 5 5 0 005-5 5 5 0 00-5-5zm0 1a4 4 0 014 4 4 4 0 01-4 4 4 4 0 01-4-4 4 4 0 014-4z"
            />
        </svg>
    );
}
