import React from 'react';
import styles from './Scroll06.module.scss'

const TEXT = {
    title: 'Video Support',
    subtitle: 'From Anywhere in the world',
    description: 'Your support team can access the system through a PC web browser, or can also download mobile apps (iOS/Android) to provide video help-desk support wherever they are!'
};

function Scroll06() {
    return (
        <div className={styles.container}>
            <div className={styles.text}>
                <h1>{TEXT.title}</h1>
                <h2>{TEXT.subtitle}</h2>
                <p>{TEXT.description}</p>
            </div>
            <div className={styles.photo}>
                <div className={styles.collage}>
                </div>
            </div>
        </div>
    );
}

export default Scroll06;
