import React from "react";

function CircleCheckMark() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" height="24" width="24">
            <circle cx="12" cy="12" r="12" fill="#fff"/>
            <path stroke="#1A1B35" strokeWidth="2" d="M5.5 11l4.5 4.5L17.5 8"/>
        </svg>
    );
}

export default CircleCheckMark;
