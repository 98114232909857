import React from "react";

function ArrowRight() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 16">
            <path fill="#CBCBD0" d="M20.267 9H0V7h20.269l-5.386-5.616L16.327 0l7.007 7.307.664.693-.664.692-7.007 7.307-1.444-1.384L20.267 9z"/>
        </svg>
    );
}

export default ArrowRight;
