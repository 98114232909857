import React, {useEffect, useState} from 'react';
import styles from './Scroll04.module.scss';
import {HeadSupport, InfoBook, Lock, Message, Settings, SignPost} from '../../../components/Icons';
import TextWithIcon from '../../../components/TextWithIcon/TextWithIcon';
import CarouselComponent from '../../../components/CarouselComponent/CarouselComponent';

const TEXT = {
    thin: [`We're making every space,`, 'place and object a portal'],
    bold: 'to a world of help.',
    icons: {
        support: ['Instant Remote', 'Visual Support'],
        signpost: ['Wayfinding'],
        message: ['Managed', 'Services'],
        settings: ['Asset', 'Management'],
        info: ['Document', 'Repository'],
        lock: ['Facilities', 'Support']
    }
};

const TYPES = {
    PHONE: 'phone',
    TABLET: 'tablet'
};

const PAGES = [
    {
        key: 0,
        icon: HeadSupport,
        content: <div>Head</div>,
        text: TEXT.icons.support,
        types: [TYPES.PHONE, TYPES.TABLET]
    },
    {
        key: 1,
        icon: SignPost,
        content: <div>SignPost</div>,
        text: TEXT.icons.signpost,
        types: [TYPES.PHONE, TYPES.TABLET]
    },
    {
        key: 2,
        icon: Message,
        content: <div>Message</div>,
        text: TEXT.icons.message,
        types: [TYPES.PHONE],
        label: 'Text chat'
    },
    {
        key: 3,
        icon: Settings,
        content: <div>Settings</div>,
        text: TEXT.icons.settings,
        types: [TYPES.PHONE]
    },
    {
        key: 4,
        icon: InfoBook,
        content: <div>Info</div>,
        text: TEXT.icons.info,
        types: [TYPES.PHONE, TYPES.TABLET]
    },
    {
        key: 5,
        icon: Lock,
        content: <div>Lock</div>,
        text: TEXT.icons.lock,
        types: [TYPES.PHONE, TYPES.TABLET]
    }
];


function Scroll04() {
    const [selectedTab, setSelectedTab] = useState(0);
    const [selectedType, setSelectedType] = useState(TYPES.PHONE);
    useEffect(() => {
        if (PAGES[selectedTab].types.indexOf(selectedType) < 0) setSelectedType(PAGES[selectedTab].types[0]);
    }, [selectedTab]);
    const contentUnderPhoto = PAGES[selectedTab].label ||
        (PAGES[selectedTab].types.length > 1
                ? <Switcher tabs={PAGES[selectedTab].types} onChange={setSelectedType} selectedType={selectedType}/>
                : ''
        );
    return (
        <div className={styles.container}>
            <div className={styles.text}>
                {TEXT.thin.map((row, i) => <h1 key={`h1_${i}`}>{row}</h1>)}
                <h2>{TEXT.bold}</h2>
            </div>
            <div className={styles.smartphone}>
                <CarouselComponent activeIndex={selectedType === TYPES.PHONE ? 0 : 1}>
                    <div key={`phone_${selectedTab}`} className={`${styles[`phone_${selectedTab}`]}`}/>
                    <div key={`tablet_${selectedTab}`} className={`${styles[`tablet_${selectedTab}`]}`}/>
                </CarouselComponent>
                <div className={styles.switcher}>
                    {contentUnderPhoto}
                </div>
            </div>
            <div className={styles.icons}>
                {PAGES.map((page, i) =>
                    <TextWithIcon
                        key={i}
                        className={styles.element}
                        icon={page.icon}
                        active={selectedTab === page.key}
                        text={page.text}
                        onHover={() => setSelectedTab(page.key)}
                    />
                )}
            </div>
        </div>
    );
}

function Switcher({tabs, onChange, selectedType}) {
    const last = tabs.length - 1;
    return tabs.map((tab, index) => {
        return (
            <>
                {index === last && <div className={styles.or}>or
                    <div className={styles.underline}/>
                </div>}
                <div className={selectedType === tab ? styles.selected : ''} onClick={() => onChange(tab)}>
                    {tab}
                    <div className={styles.underline}/>
                </div>
            </>
        );
    });
}

export default Scroll04;
