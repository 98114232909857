// /*global eruda*/
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import styles from './assets/style/Root.module.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));
serviceWorker.unregister();


// (function () {
//     var script = document.createElement('script');
//     script.src = "//cdn.jsdelivr.net/npm/eruda";
//     document.body.appendChild(script);
//     script.onload = function () {
//         eruda.init()
//     }
// })();

