import React from "react";

function TryNowIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 96 96" height="96" width="96">
            <circle cx="48" cy="48" r="47.5" stroke="#5465E6" strokeOpacity="0.5" />
            <circle cx="48" cy="48" r="32" fill="#5465E6" />
            <path
                fill="#fff"
                d="M43 39a1 1 0 00-1 1v16a1 1 0 001 0l14-7a1 1 0 001-1 1 1 0 00-1-1l-14-7a1 1 0 000-1z"/>
        </svg>
    );
}

export default TryNowIcon;
