import React from "react";
import PropTypes from "prop-types";

Signpost.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    activeColor: PropTypes.string,
    active: PropTypes.bool
};

Signpost.defaultProps = {
    className: "",
    color: "var(--darkblue)",
    activeColor: "var(--white)",
    active: false
};

export default function Signpost({color='var(--darkblue)', activeColor='var(--white)', className="", active=false}) {
    const actualColor = active ? activeColor : color;
    return (
        <svg className={className} width="30" height="32" viewBox="0 0 30 32" fill="none">
            <path
                d="M15 5V3a1 1 0 112 0v2h10l3 3v1l-3 3-1 1h-9v4h11l1 1v6l-1 1H17v5a1 1 0 11-2 0v-5H6l-1-1-3-3v-1l3-3h10v-4H4l-1-1V6l1-1h11zm10 2H5v4h20l2-2-2-2zm2 12H7l-2 2 2 2h20v-4z"
                fill={actualColor}
            />
        </svg>
    );
}
