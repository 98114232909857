import React from 'react';
import './App.css';
import AppRouters from "./routes";
import ContentContextContainer from "./context/ContentContext";

function App() {
  return (
    <div className="App">
        <ContentContextContainer>
            <AppRouters/>
        </ContentContextContainer>
    </div>
  );
}

export default App;
