import React from "react";
import PropTypes from "prop-types";

MouseSmall.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    opacity: PropTypes.number
};

MouseSmall.defaultProps = {
    className: "",
    color: "white",
    opacity: 1
};

export default function MouseSmall({color, className, opacity}) {
    return (
        <svg style={{transition:'1s ease-in-out'}} className={className} width="30" height="40" viewBox="0 0 30 40" fill='none' >
            <path
                d="M1 15C1 7 7 1 15 1s14 6 14 14v10c0 8-6 14-14 14S1 33 1 25V15z"
                stroke={color}
                strokeWidth="2"
            />
            <path d="M14 11h2v10h-2z" fill={color} opacity={opacity}/>
        </svg>
    );
}
