const RULES = {
    msg: [
        {
            regexp: /./,
            errorMessage: 'Message should not be empty'
        },
    ],
    email: [
        {
            regexp: /./,
            errorMessage: 'Email should not be empty'
        },
        {
            regexp: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
            errorMessage: 'Email is not valid'
        },
    ],
    name: [
        {
            regexp: /./,
            errorMessage: 'Name should not be empty'
        },
        {
            regexp: /^ /,
            isNegative: true,
            errorMessage: `Name Shouldn't start with a space`
        },
        {
            regexp: / {2}/,
            isNegative: true,
            errorMessage: `More than one space in a row is not allowed`
        },
        {
            regexp: /^[a-zA-Z0-9 -]*$/,
            errorMessage: 'Invalid symbol'
        },
    ]
};
export default function validate(field, value){
    return RULES[field] && RULES[field].filter(rule => {
        const notMatching = value.search(rule.regexp) < 0;
        return rule.isNegative ? !notMatching : notMatching;
    })[0];
}
