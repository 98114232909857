import PropTypes from 'prop-types';
import React from 'react';

import styles from './HorizontalNavigationBar.module.scss';
import ArrowLeft from '../Icons/ArrowLeft';
import ArrowRight from '../Icons/ArrowRight';
import classnames from 'classnames';

HorizontalNavigationBar.propTypes = {
    count: PropTypes.number,
    activeIndex: PropTypes.number,
    onClickRight: PropTypes.func,
    onClickLeft: PropTypes.func,
    onClickIndex: PropTypes.func,
};

HorizontalNavigationBar.defaultProps = {
    count: 1,
    activeIndex: 0,
    onClickRight: f => f,
    onClickLeft: f => f,
    onClickIndex: f=> f,
};

export default function HorizontalNavigationBar(props) {
    const {count, activeIndex, onClickLeft, onClickRight, onClickIndex, className} = props;

    const currentStateStyle = {
        width: `${100 / count}%`,
        left: `${activeIndex * 100 / count}%`
    };
    return (
        <div className={className}>
            <div className={styles.navigationBarContainer}>
                <div className={styles.stateBar}>
                    <span style={currentStateStyle}/>
                </div>
                <div className={styles.arrows}>
                    <button onClick={onClickLeft}>
                        <ArrowLeft/>
                    </button>
                    <button onClick={onClickRight}>
                        <ArrowRight/>
                    </button>
                </div>
            </div>
            <div className={styles.mobileNavigationBarContainer}>
                {[...Array(count)].map((el, i) => {
                    const className = classnames(styles.circle, (i === activeIndex) && styles.selected);
                    return <div key={i} className={className} onClick={() => onClickIndex(i)}/>
                })}
            </div>
        </div>
    );
}
