import React from 'react';
import PropTypes from 'prop-types';

Icon.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    activeColor: PropTypes.string,
    active: PropTypes.bool
};

Icon.defaultProps = {
    className: '',
    color: 'var(--darkblue)',
    activeColor: 'var(--white)',
    active: false
};

export default function Icon({color = 'var(--darkblue)', activeColor = 'var(--white)', className = '', active = false}) {
    const actualColor = active ? activeColor : color;
    return (
        <svg width="30" height="32" viewBox="0 0 30 32" fill="none">
            <path
                d="M1.018 3.765v0C1.018 2.236 2.25 1 3.75 1h22.5C27.756 1 29 2.243 29 3.765v16.94c0 1.523-1.244 2.766-2.75 2.766H7.085l-.293.294-5.79 5.813.016-25.813z"
                stroke={actualColor}
                strokeWidth="2"
            />
            <rect x="7" y="6" width="16" height="2" rx="1" fill={actualColor}/>
            <rect x="7" y="11" width="16" height="2" rx="1" fill={actualColor}/>
            <rect x="7" y="16" width="8" height="2" rx="1" fill={actualColor}/>
        </svg>
    );
}
