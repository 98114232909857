import PropTypes from 'prop-types';
import React, {useState, useEffect, useRef} from 'react';


import styles from './CarouselComponent.module.scss';
import classnames from 'classnames';
import {Swipeable} from 'react-swipeable';

CarouselComponent.propTypes = {
    activeIndex: PropTypes.number,
    isBackWard: PropTypes.bool,
    onSwipedLeft: PropTypes.func,
    onSwipedRight: PropTypes.func,
};

CarouselComponent.defaultProps = {
    activeIndex: 0,
    isBackWard: false,
    onSwipedLeft: f => f,
    onSwipedRight: f => f,
};

export default function CarouselComponent(props) {
    const {activeIndex, children, isBackWard, onSwipedLeft, onSwipedRight} = props;
    const [indexState, setIndexes] = useState({});
    useEffect(() => {
        setIndexes({prev: indexState.cur, cur: activeIndex});
    },[activeIndex]);

    return (
        <Swipeable onSwipedLeft={onSwipedLeft} onSwipedRight={onSwipedRight} className={styles.carouselContainer}>
            {children.map((el, i) => {
                const className = classnames(
                    {[styles.activeCard]: i === indexState.cur},
                    {[styles.previousCard]: i === indexState.prev},
                    {[styles.reverse]: !isBackWard },
                    );
                return <div key={i} className={className}>{el}</div>
            })}
        </Swipeable>
    );
}
