import React from "react";
import PropTypes from "prop-types";

Play.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string
};

Play.defaultProps = {
    className: "",
    color: "white"
};

export default function Play({color, className}) {
    return (
        <svg width="24" height="14" viewBox="0 0 24 14" fill="none" className={className}>
            <rect width="24" height="2" fill={color}/>
            <rect y="6" width="24" height="2" fill={color}/>
            <rect x="8" y="12" width="16" height="2" fill={color}/>
        </svg>
    );
}
