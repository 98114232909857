import React, {useContext, useEffect, useState} from 'react';
import classnames from 'classnames';
import styles from './Header.module.scss';
import {Close, Logo, Menu} from '../components/Icons';
import {ContentContext} from '../context/ContentContext';
import {NavigationContext} from '../context/NavigationContextContainer';

import {THEMES} from '../helpers/constants';

function Header() {
    const {showMenu, setShowMenu, headerColor, headerType, isVideoPlaying} = useContext(ContentContext);
    const {goHome} = useContext(NavigationContext);
    const [isPageScrolled, setIsPageScrolled] = useState(false);
    useEffect(() => {
        window.onscroll = () => {
            if (window.pageYOffset > 50) {
                setIsPageScrolled(true);
            } else {
                setIsPageScrolled(false);
            }
        };
    }, []);
    const onToggleMenu = () => {
        if (isVideoPlaying) return;
        setShowMenu(!showMenu);
    };
    const colorStyle = (headerColor === THEMES.LIGHT && !isPageScrolled) ? styles.light : styles.dark;
    const containerStyle = isPageScrolled && !showMenu && styles.whiteBackground;
    return (
        <div className={classnames(styles.container, styles[headerType], containerStyle)}>
            <div className={styles.logo} onClick={goHome}>
                <Logo className={classnames(colorStyle, showMenu && styles.light)}/>
            </div>
            <div className={classnames(styles.register, showMenu && styles.hidden, colorStyle)}>
                <a target="_blank" rel='noopener' href='https://portal.teleportivity.com/register'>GET STARTED</a>
            </div>
            <div className={styles.menuButton} onClick={onToggleMenu}>
                {showMenu
                    ? <Close/>
                    : <Menu className={colorStyle}/>
                }
            </div>
        </div>
    );
}

export default Header;
