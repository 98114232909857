import React from "react";

function ArrowLeft() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 16">
            <path fill="#CBCBD0" d="M3.73 9h20.268V7H3.729l5.386-5.616L7.67 0 .664 7.307 0 8l.664.692 7.007 7.307 1.444-1.384L3.73 9z"/>
        </svg>
    );
}

export default ArrowLeft;
