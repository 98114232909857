import React from "react";

function Android() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fill="#fff" d="M7.815.016c-.062.011-.126.022-.18.06a.489.489 0 00-.12.675L8.55 2.296c-1.6.847-2.792 2.255-3.18 3.945h13.26c-.388-1.69-1.58-3.098-3.18-3.945L16.485.75a.489.489 0 00-.12-.675.492.492 0 00-.675.135l-1.14 1.68A7.425 7.425 0 0012 1.44c-.9 0-1.759.161-2.55.45L8.31.21a.482.482 0 00-.495-.195zM9.36 3.84a.72.72 0 110 1.44.72.72 0 010-1.44zm5.28 0a.719.719 0 110 1.44.719.719 0 110-1.44zM3.84 7.2A1.44 1.44 0 002.4 8.64v6.72c0 .795.645 1.44 1.44 1.44.169 0 .33-.036.48-.09V7.29a1.408 1.408 0 00-.48-.09zm1.44 0v10.56c0 .793.647 1.44 1.44 1.44h10.56c.793 0 1.44-.647 1.44-1.44V7.2H5.28zm14.88 0c-.169 0-.33.035-.48.09v9.42c.15.052.311.09.48.09a1.44 1.44 0 001.44-1.44V8.64a1.44 1.44 0 00-1.44-1.44zM7.2 20.16v1.92a1.92 1.92 0 003.84 0v-1.92H7.2zm5.76 0v1.92a1.92 1.92 0 003.84 0v-1.92h-3.84z" opacity="0.5"/>
            <defs>
                <clipPath id="clip0">
                    <path fill="#fff" d="M0 0h24v24H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default Android;
