export { default as Play } from "./Play";
export { default as MouseSmall } from "./MouseSmall";
export { default as Logo } from './Logo';
export { default as Menu } from './Menu';
export { default as Close } from './Close';
export { default as Letter } from './Letter';
export { default as Message } from './Message';
export { default as InfoBook } from './InfoBook';
export { default as HeadSupport } from './HeadSupport';
export { default as Lock } from './Lock';
export { default as Settings } from './Settings';
export { default as SignPost } from './Signpost';
