import React from "react";
import PropTypes from "prop-types";

Icon.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    activeColor: PropTypes.string,
    active: PropTypes.bool
};

Icon.defaultProps = {
    className: "",
    color: "var(--white)",
    activeColor: "var(--active-color)",
    active: false
};

export default function Icon({color='var(--darkblue)', activeColor='var(--white)', className="", active=false}) {
    const actualColor = active ? activeColor : color;
    return (
        <svg className={className} width="32" height="26" viewBox="0 0 32 26" fill="none">
            <rect x="1" y="1" width="30" height="24" rx="2" stroke={actualColor} strokeWidth="2"/>
            <rect x="15" width="2" height="26" fill={actualColor}/>
            <rect x="22.5" y="12" width="2" height="6.5" rx="1" fill={actualColor}/>
            <rect x="22.5" y="8" width="2" height="2" rx="1" fill={actualColor} stroke={actualColor} strokeWidth="0.5"/>
            <rect x="5" y="8" width="7" height="2" rx="1" fill={actualColor}/>
            <rect x="5" y="12" width="7" height="2" rx="1" fill={actualColor}/>
            <rect x="5" y="16" width="7" height="2" rx="1" fill={actualColor}/>
        </svg>
    );
}
