import React, {useContext, useEffect, useRef} from 'react';
import {Scroll01, Scroll04Mobile} from './ScrollPages';
import ReactPageScroller from 'react-page-scroller';
import {ContentContext} from '../context/ContentContext';
import {useWindowSize} from 'react-use';
import dynamicComponent from '../helpers/dynamicComponent';
import {MOBILE_SIZE_EDGE} from '../helpers/constants';

import {THEMES} from '../helpers/constants';

function HomePage(props) {
    const {isMobileLayout, pageOnChange, scrollerRef, setHeaderColor, setCurrentPageIndex, scrollBlocked, setHeaderType} = useContext(ContentContext);
    const Sections = useRef({
        Scroll02di: dynamicComponent(() => import('./ScrollPages/Scroll02')),
        Scroll03di: dynamicComponent(() => import('./ScrollPages/Scroll03')),
        Scroll04di: dynamicComponent(() => import('./ScrollPages/scroll04/Scroll04')),
        Scroll05di: dynamicComponent(() => import('./ScrollPages/Scroll05')),
        Scroll06di: dynamicComponent(() => import('./ScrollPages/Scroll06')),
        Scroll07di: dynamicComponent(() => import('./ScrollPages/Scroll07')),
        Scroll08di: dynamicComponent(() => import('./ScrollPages/Scroll08'))
    });
    useEffect(() => {
        setHeaderColor(THEMES.LIGHT);
        setCurrentPageIndex(1);
    }, []);

    const {width} = useWindowSize();
    useEffect(() => {
        if (width <= MOBILE_SIZE_EDGE) {
            setHeaderType('reduceOnScroll');
        }
        return () => {
            setHeaderType('');
        };
    }, [width]);


    return !isMobileLayout
        ? <ReactPageScroller
            animationTimer={1000}
            pageOnChange={pageOnChange}
            ref={scrollerRef}
            blockScrollDown={scrollBlocked}
            blockScrollUp={scrollBlocked}
        >
            <Scroll01/>
            <Sections.current.Scroll02di key={'scroll2'}/>
            <Sections.current.Scroll03di key={'scroll3'}/>
            <Sections.current.Scroll04di key={'scroll4'}/>
            <Sections.current.Scroll05di key={'scroll5'}/>
            <Sections.current.Scroll06di key={'scroll6'}/>
            <Sections.current.Scroll07di key={'scroll7'}/>
            <Sections.current.Scroll08di key={'scroll8'}/>
        </ReactPageScroller>
        : <div>
            <Scroll01/>
            <Sections.current.Scroll02di key={'scroll2'}/>
            <Sections.current.Scroll03di key={'scroll3'}/>
            <Scroll04Mobile/>
            <Sections.current.Scroll05di key={'scroll5'}/>
            <Sections.current.Scroll06di key={'scroll6'}/>
            <Sections.current.Scroll07di key={'scroll7'}/>
            <Sections.current.Scroll08di key={'scroll8'}/>
        </div>;
}

export default HomePage;
